html {
  font-size: 16px;
  box-sizing: border-box;
}

@media only screen and (max-width: 1400px) {

  html,
  body {
    font-size: 14px;
  }
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: #fff;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
  height: 5px;
  ;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888856;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #88888856;
}

#root {
  height: 100%;
  background: white;
}

.page-overflow {
  height: 100%;
  max-height: 100%;
  overflow: scroll;
}

p {
  margin: 0;
}

button,
.btn {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
}

.btn:hover,
button:hover {
  cursor: pointer;
}

.require-input {
  color: #F00;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn.btn-primary {
  display: flex;
  width: 100%;
  height: 3rem;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 2px;
  background: #0561AE;
  color: var(--text-text-1, #FFF);
  text-align: center;

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn.btn-default {
  display: flex;
  width: 100%;
  height: 3rem;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 2px;
  background: #E3E9ED;
  color: #566A7F;
  text-align: center;

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 2px;
  border: 1px solid var(--line-line-3, #86909C);
  background: var(--fill-fill-2, #E3E9ED);
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.password-input {
  -webkit-text-security: disc;
  text-security: disc;
}